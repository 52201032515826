import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { BaseComponent } from './views/layout/base/base.component';
import { ForbiddenComponent } from './views/pages/forbidden/forbidden.component';
const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) }
  , {
    path: '',
    component: BaseComponent,
    children: [
      { path: 'forbidden', component: ForbiddenComponent, canActivate: [AuthGuard] },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'manage',
        loadChildren: () => import('./modules/manage/manage.module').then(m => m.ManagementModule),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'memeberregistration',
        loadChildren: () => import('./modules/member-registration/memberregistration.module').then(m => m.MemeberregistrationModule)
        , canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'enrollment',
        loadChildren: () => import('./modules/enrollment/enrollment.module').then(m => m.EnrollmentModule)
        , canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'member-receipt',
        loadChildren: () => import('./modules/member-receipt/member-receipt.module').then(m => m.MemberReceiptModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'auction-and-payments',
        loadChildren: () => import('./modules/auction-and-payments/auction-and-payments.module').then(m => m.AuctionAndPaymentsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.paymentsModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'employee-salary',
        loadChildren: () => import('./modules/employee-salary/employee-salary.module').then(m => m.EmployeeSalaryModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'crm',
        loadChildren: () => import('./modules/crm/crm.module').then(m => m.CRMModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'rdfd',
        loadChildren: () => import('./modules/rdfd/rdfd.module').then(m => m.RdfdModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      // {
      //    path: 'report',
      //    loadChildren: () => import('./modules/report/report.module').then(m=> m.ReportModule)
      // },
      {
        path: 'loans',
        loadChildren: () => import('./modules/loans/loans.module').then(m => m.LoansModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      {
        path: 'report',
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)
        , canActivate: [AuthGuard]
        , data: { roles: ['Admin', 'Clerk'] }
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
