import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from './user.profile';
import { IProfile, User } from './user.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../../../environments/environment.prod';
import { from, Observable, throwError } from 'rxjs';

@Injectable()
export class UserService {
    redirectUrl: string;
    errorMessage: string;
    constructor(
        private router: Router,
        private authProfile: UserProfile,
        private HTTP: HttpClient
    ) { }

    saveUser(model: any): Observable<any> {
        return this.HTTP.post<any>(`${environment.API_Base_URL}` + "/user/Register", model);
    }

    isAuthenticated() {
        let profile = this.authProfile.getProfile();
        var validToken = profile.token != "" && profile.token != null;
        // var isTokenExpired = this.isTokenExpired(profile);
        return validToken;// && !isTokenExpired;
    }
    isAuthorized() {
        let profile = this.authProfile.getProfile();
        var validToken = profile.token != "" && profile.token != null;
        //   var isTokenExpired = this.isTokenExpired(profile);
        return validToken //&& !isTokenExpired;
    }
    isTokenExpired(profile: IProfile) {
        var expiration = new Date(profile.expiration)
        return expiration < new Date();
    }

    roleMatch(allowedRoles): boolean {
        var isMatch = false;
        var userRoles: string[] = JSON.parse(sessionStorage.getItem('userRoles'));
        allowedRoles.forEach(element => {
            if (userRoles.indexOf(element) > -1) {
                isMatch = true;
                return false;
            }
        });
        return isMatch;

    }
    // login(model: any) {
    //     this.HTTP.post(`${environment.apiBase}` + "api/auth/login", model).subscribe((Result: any) => {
    //         var userProfile: IProfile = Result;
    //         this.authProfile.setProfile(userProfile);
    //         this.router.navigate([""]);
    //     }, error => {
    //         var Result = {
    //             'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRFIiLCJ1c2VyX2lkIjoiMiIsInVzZXJfdHlwZSI6IkFkdmlzb3IiLCJtZW1iZXJfaWQiOiI1IiwianRpIjoiOGYzMmQ4ODktNmJhOC00NDY3LTk5NGQtOGIxOTRhYjc3MDEyIiwiZXhwIjoxNjAwNjc4ODM2LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjU2NjQ2IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0MjAwIn0.th_3oPQ9OceAf5OLmxXw_GjOUQg8xbbTaUefsyU3PxY',
    //             'expiration': '2020-09-21T09:00:36Z',
    //             'claim': [
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    //                     'value': 'DR',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'user_id',
    //                     'value': '2',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'user_type',
    //                     'value': 'Advisor',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'member_id',
    //                     'value': '5',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'jti',
    //                     'value': '8f32d889-6ba8-4467-994d-8b194ab77012',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'exp',
    //                     'value': '1600678836',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#integer'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'iss',
    //                     'value': 'http://localhost:56646',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 },
    //                 {
    //                     'issuer': 'http://localhost:56646',
    //                     'originalIssuer': 'http://localhost:56646',
    //                     'properties': {},
    //                     'subject': null,
    //                     'type': 'aud',
    //                     'value': 'http://localhost:4200',
    //                     'valueType': 'http://www.w3.org/2001/XMLSchema#string'
    //                 }
    //             ]
    //         };
    //         var userProfile: IProfile = Result;
    //         this.authProfile.setProfile(userProfile);
    //         this.router.navigate([""]);
    //     });

    // }

    getUserClaims() {
        return this.HTTP.get(`${environment.API_Base_URL}` + 'GetUserClaims');
    }


    logout(): void {
        this.authProfile.resetProfile();
    }
}