import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class httpInterceptor implements HttpInterceptor {

    /**
     *
     */
    constructor(private router: Router) {

    }
    intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.get('No-Auth') == "True")
            return newRequest.handle(request.clone());
        console.log('httpintece');
        let tokenInfo = sessionStorage.getItem('access_token');
        // console.log(tokenInfo);
        // if (tokenInfo != null) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${tokenInfo}`
            }
        });
        console.log(request);
        return newRequest.handle(request);
        // }
        // else
        //     this.router.navigate(['/auth/login']);
    }
}