import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Navigation',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Manage',
    icon: 'book',
    subItems: [
      {
        label: 'CFMS Control',
        link: '/manage/cfms-control',
      },
      {
        label: 'Calendar',
        link: '/manage/calendar',
      },
      {
        label: 'Cheque Book Control',
        link: '/manage/chequebookcontrol',
      },
      {
        label: 'Branch',
        link: '/manage/branch',
      },
      {
        label: 'Bank',
        link: '/manage/bank',
      },
      {
        label: 'Area',
        link: '/manage/area',
      },
      {
        label: 'Advisor Registration',
        link: '/manage/agent-registration',
      },
      {
        label: 'Collection Agent',
        link: '/manage/collection-agent',
      },
      {
        label: 'Tr Book',
        link: '/manage/tr-book',
      },
      {
        label: 'Chit Amount',
        link: '/manage/chit-amount',
      },
      {
        label: 'ReceiptType',
        link: '/manage/receipt-type',
      },
      {
        label: 'Employee',
        link: '/manage/employee',
      },
      {
        label: 'Assign-coll-agent',
        link: '/manage/assigncollagent',
      },
    ]
  },
  {
    label: 'Subscriber',
    icon: 'book',
    subItems: [
      {
        label: 'Subscriber KYC',
        link: '/memeberregistration/createmember',
      },
      {
        label: 'View Subscriber',
        link: '/memeberregistration/viewmember',
      },
      // {
      //   label: 'Update Subscriber KYC',
      //   link: '/memeberregistration/updatemember',
      // },
      {
        label: 'Assign Member',
        link: '/memeberregistration/assignmember',
      },
      {
        label: 'Subscriber Document',
        link: '/memeberregistration/memberdocument',
      },
      {
        label: 'Nominee Document',
        link: '/memeberregistration/nominee-document',
      },
      {
        label: 'Online Member',
        link: '/memeberregistration/onlinedocument',
      }
      , {
        label: 'Guarantor Registration',
        link: '/memeberregistration/guarantorregistration',
      }
    ]
  },
  {
    label: 'Schemes & Enrollment',
    icon: 'user',
    subItems: [
      {
        label: 'Create New Group',
        link: '/enrollment/create-new-group',
      },
      // {
      //   label: 'Fixed Plan',
      //   link: '/enrollment/fixedplan',
      // },
      {
        label: 'Interested Member',
        link: '/enrollment/interestedmember',
      },
      // {
      //   label: 'Member Enquiry',
      //   link: '/enrollment/member-enquiry',
      // },
      {
        label: 'Group Fd',
        link: '/enrollment/group-fd',
      },
      {
        label: 'Approve Group',
        link: '/enrollment/approve-group',
      },

      {
        label: 'Groups And Enrollment',
        link: '/enrollment/groups-and-enrollment',
      },
      // {
      //   label: 'Groups And Enrollment Approval',
      //   link: '/enrollment/groups-and-enrollment-approval',
      // },
      {
        label: 'Enrollment Approval',
        link: '/enrollment/enrollment-approval',
      },
      {
        label: 'Chit Surrender',
        link: '/enrollment/chit-surrender',
      },
      {
        label: 'Chit Surrender Approval',
        link: '/enrollment/chit-surrender-approval',
      },
      {
        label: 'Chit Transfer',
        link: '/enrollment/chit-transfer',
      },
      {
        label: 'Chit Transfer Approval',
        link: '/enrollment/chit-transfer-approval',
      },
      // {
      //   label: '',
      //   link: '/enrollment/',
      // },
      // {
      //   label: '',
      //   link: '/enrollment/',
      // },
      // {
      //   label: 'Fixed Plan Group',
      //   link: '/enrollment/fixed-plan',
      // },
      /* {
        label: 'Interested Member',
        link: '/enrollment/interested-member',
      },
      {
        label: 'Member Enquiry',
        link: '/enrollment/member-enquiry',
      },
      {
        label: 'Group Fd',
        link: '/enrollment/group-fd',
      } */
    ]
  },
  {
    label: 'Auction & Payment',
    icon: 'edit',
    subItems: [
      {
        label: 'Intimation',
        subItems: [
          {
            label: 'Send Intimation',
            link: '/auction-and-payments/intimation/send-intimation',
          },
          {
            label: 'View Intimation',
            link: '/auction-and-payments/intimation/view-intimation',
          },
          {
            label: 'Print Intimation',
            link: '/auction-and-payments/intimation/print',
          }
        ]
      },
      {
        label: 'Auction',
        subItems: [
          {
            label: 'Auction Entry',
            link: '/auction-and-payments/chit-payment/auction',
          },
          {
            label: 'Auction Approval',
            link: '/auction-and-payments/chit-payment/approve-auction',
          },
          {
            label: 'Re-Auction Entry',
            link: '/auction-and-payments/chit-payment/re-auction',
          },
          {
            label: 'Re-Auction Approval',
            link: '/auction-and-payments/chit-payment/re-auction-approval',
          }


        ]
      },
      {
        label: 'Security Registration',
        subItems: [
          {
            label: 'Collateral',
            link: '/auction-and-payments/security-reg/collateral',
          },
          {
            label: 'Collateral Approval',
            link: '/auction-and-payments/security-reg/collateral-approval',
          },
          {
            label: 'Personal Acceptance Entry',
            link: '/auction-and-payments/security-reg/personal-acceptance',
          },
          {
            label: 'Personal Acceptance Approval',
            link: '/auction-and-payments/security-reg/personal-acceptance-approval',
          },
          {
            label: 'Financial Document Entry',
            link: '/auction-and-payments/security-reg/finanical-document',
          },
          {
            label: 'Financial Document Approval',
            link: '/auction-and-payments/security-reg/finanical-document-approval',
          },
          {
            label: 'Personal Security Entry',
            link: '/auction-and-payments/security-reg/personal-surety',
          },
          {
            label: 'Personal Security Approval',
            link: '/auction-and-payments/security-reg/personal-surety-approval',
          },
          {
            label: 'Non-prized PassBook Entry',
            link: '/auction-and-payments/security-reg/chitty-passbook',
          },
          {
            label: 'Non-prized PassBookApproval',
            link: '/auction-and-payments/security-reg/chitty-passbook-approval',
          },
        ]
      },

      {
        label: 'Prize Money',
        subItems: [
          {
            label: 'Prize Money payments',
            link: '/auction-and-payments/chit-payment/prize-payment',
          },
          {
            label: 'Prize Money Approval payments',
            link: '/auction-and-payments/chit-payment/payment-approve',
          },
          {
            label: 'Prize Payments View',
            link: '/auction-and-payments/chit-payment/prize-payment-view',
          }
        ]

      },
      {
        label: 'Lucky Divident',
        subItems: [
          {
            label: 'Lucky Divident Entry',
            link: '/auction-and-payments/lucky-divident/lucky-divident',
          },
          {
            label: 'Relationship Manager',
            link: '/auction-and-payments/lucky-divident/lucky-divident-payment',
          },
          {
            label: 'Staff Registration',
            link: '/auction-and-payments/lucky-divident/lucky-divident-payment-list',
          }
        ]
      }
    ]
  },
  {
    label: 'Member-receipt',
    icon: 'credit-card',
    subItems: [
      {
        label: 'Reciept Entry',
        link: '/member-receipt/receipt-entry',
      },
      {
        label: 'Reciept View',
        link: '/member-receipt/receipt-view',
      },
      {
        label: 'Reciept Approval',
        link: '/member-receipt/receipt-approval',
      },
      {
        label: 'Cash/Cheque Entry',
        link: '/member-receipt/cash-cheque-deposit',
      },
      {
        label: 'Cash/Cheque Approval',
        link: '/member-receipt/cash-cheque-approval',
      },
      {
        label: 'Deposit View',
        link: '/member-receipt/deposit-view',
      },
      {
        label: 'Daily Collection Entry',
        subItems: [
          {
            label: 'Daily Receipt Entry',
            link: '/member-receipt/daily-collection-entry/daily-receipt-entry',
          },
          {
            label: 'Daily Receipt List',
            link: '/member-receipt/daily-collection-entry/daily-receipt-list',
          },
          {
            label: 'Daily Receipt Report',
            link: '/member-receipt/daily-collection-entry/daily-receipt-report',
          }
        ]
      },
      {
        label: 'Receipt Confrim',
        link: '/member-receipt/receipt-confrim',
      }
    ]
  },
  // {
  //   label: 'Loans',
  //   icon: 'credit-card',
  //   subItems: [
  //     {
  //       label: 'LoanPayments',
  //       link: '/loans/loanreceiptentey',
  //     },
  //     {
  //       label: 'LoanList',
  //       link: '/loans/loanlist',
  //     },
  //   ]
  // },
  {
    label: 'Payment',
    icon: 'credit-card',
    subItems: [
      {
        label: 'Advisor Payment',
        link: '/payments/advisor-payment',
      },
      {
        label: 'Incentive Payment',
        link: '/payments/incentive-payment',
      },
      {
        label: 'Return Payment',
        link: '/payments/return-payment',
      },
      {
        label: 'Return Payment Approval',
        link: '/payments/return-payment-approval',
      },
      {
        label: 'RSC Payment',
        link: '/payments/rsc-payment',
      },
      {
        label: 'RSC Payment Approval',
        link: '/payments/rsc-payment-approval',
      },
      {
        label: 'APSTC Payment',
        link: '/payments/apstc-payment',
      },
      {
        label: 'APSTC Payment Approval',
        link: '/payments/apstc-payment-approval',
      },

      {
        label: 'Chit Against Advance',
        subItems: [
          {
            label: 'Advanced Payment',
            link: '/payments/chit-against-advance/advance-payments',
          },
          {
            label: 'Advanced Payments List',
            link: '/payments/chit-against-advance/advanced-payment-list',
          }
        ]
      }
    ]
  },
  {
    label: 'Accounts',
    icon: 'database',
    subItems: [
      {
        label: 'Financial Year',
        link: '/account/financial-year',
      },
      {
        label: 'Create Account',
        link: '/account/create'
      },
      {
        label: 'Day Book',
        link: '/account/day-book'
      },
      {
        label: 'Bank Book',
        link: '/account/bank-book'
      },
      {
        label: 'Cash Book',
        link: '/account/cash-book'
      },
      {
        label: 'Bank Reconciliation',
        link: '/account/bank-reconciliation'
      },
      {
        label: 'Vouchers',
        subItems: [
          {
            label: 'Manual Voucher',
            link: '/account/manual-voucher',
          },
          {
            label: 'Voucher Approval',
            link: '/account/voucher-approval',
          },
          {
            label: 'Journal Voucher',
            link: '/account/voucher-list',
          }
        ]
      },
      {
        label: 'Account Ledger',
        link: '/account/account-ledger'
      },
      {
        label: 'Balance Sheet',
        link: '/account/balance-sheet'
      },
      {
        label: 'Trial Balance',
        link: '/account/trial-balance'
      },
      {
        label: 'Profit Loss',
        link: '/account/profit-loss'
      },
      // {
      //   label: 'Transaction Locking',
      //   link: '/apps/transaction-locking'
      // }
    ]
  },
  // {
  //   label: 'Report',
  //   icon: 'bar-chart',
  //   subItems: [
  //     {
  //       label: 'Collection',
  //       subItems: [
  //         {
  //            label: 'Coll-Agent',
  //            link: '/report/Collection/collagent',
  //         },
  //         {
  //           label: 'Area Wise',
  //           link: '/report/Collection/areawise',
  //         },
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'Reports',
  //   icon: 'bar-chart',
  //   subItems: [
  //     {
  //       label: 'Collection',
  //       subItems: [
  //         {
  //           label: 'Coll Agent Wise',
  //           link: '/report/Collection/collagent',
  //         },
  //         {
  //           label: 'Area Wise',
  //           link: '/report/Collection/areawise',
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Installment Dues',
  //       subItems: [
  //         {
  //           label: 'Member Wise',
  //           link: '/apps/installment-dues/member-dues',
  //         },
  //         {
  //           label: 'Advisor Wise',
  //           link: '/apps/installment-dues/advisor-dues',
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Payments',
  //       subItems: [
  //         {
  //           label: 'Incentive',
  //           link: '/apps/payment/incentive-payment',
  //         },
  //         {
  //           label: 'Advisor',
  //           link: '/apps/payment/advisor-payment',
  //         },
  //         {
  //           label: 'Loan Payment',
  //           link: '/apps/payment/loan-payment',
  //         },
  //         {
  //           label: 'Return Payment',
  //           link: '/apps/payment/return-payment',
  //         }

  //       ]
  //     },
  //     {
  //       label: 'Action Report',
  //       subItems: [
  //         {
  //           label: 'Prize Payment Slip',
  //           link: '/apps/auction/prize-payment-slip',
  //         },
  //         {
  //           label: 'Prize Commitment',
  //           link: '/apps/auction/prize-commitment',
  //         },
  //         {
  //           label: 'Upd Report',
  //           link: '/apps/auction/upd-report',
  //         },
  //         {
  //           label: 'Lien Confirmation',
  //           link: '/apps/auction/lien-confirmation',
  //         },
  //         {
  //           label: 'Guarantor Report',
  //           link: '/apps/auction/guarantor-report',
  //         },
  //         {
  //           label: 'Bid Payable Memo',
  //           link: '/apps/auction/bid-payable-memo',
  //         },
  //         {
  //           label: 'NOC Letter',
  //           link: '/apps/auction/noc-letter',
  //         },
  //         {
  //           label: 'Auction Receipt',
  //           link: '/apps/auction/auction-receipt',
  //         },
  //         {
  //           label: 'Agreement Of Guarantee',
  //           link: '/apps/auction/agreement-of-guarantee',
  //         },
  //         {
  //           label: 'Bid Authorization',
  //           link: '/apps/auction/bid-authorization',
  //         },
  //         {
  //           label: 'Demand Note',
  //           link: '/apps/auction/demand-note',
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Group Related',
  //       subItems: [
  //         {
  //           label: 'Vacancy Report',
  //           link: '/apps/groups-related/vacancy-report',
  //         },
  //         {
  //           label: 'Group Auction List',
  //           link: '/apps/groups-related/group-auction-list',
  //         },
  //         {
  //           label: 'Auction Prize Sign',
  //           link: '/apps/groups-related/auction-prize-sign',
  //         },
  //         {
  //           label: 'Welcome Letter',
  //           link: '/apps/groups-related/welcome-letter',
  //         },
  //         {
  //           label: 'Remove List',
  //           link: '/apps/groups-related/remove-list',
  //         },
  //         {
  //           label: 'Chit Schedule',
  //           link: '/apps/groups-related/chit-schedule',
  //         },
  //         {
  //           label: 'Receipt & Payment',
  //           link: '/apps/groups-related/receipt-and-payment',
  //         },
  //         {
  //           label: 'Group Balance Sheet',
  //           link: '/apps/groups-related/group-balance-sheet',
  //         },
  //         {
  //           label: 'Group Member Print',
  //           link: '/apps/groups-related/group-member-print',
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Receipt Report',
  //       subItems: [
  //         {
  //           label: 'Member Ledger',
  //           link: '/apps/member-receipt/member-ledger',
  //         },
  //         {
  //           label: 'Member Group Ledger',
  //           link: '/apps/member-receipt/member-group-ledger',
  //         },
  //         {
  //           label: 'Adjustment Report',
  //           link: '/apps/member-receipt/adjustment-report',
  //         },
  //         {
  //           label: 'Daily Transaction',
  //           link: '/apps/member-receipt/daily-transaction',
  //         },
  //         {
  //           label: 'Daily Collection Summary',
  //           link: '/apps/member-receipt/daily-collection-summary',
  //         }
  //       ]
  //     },
  //     {
  //       label: 'MIS',
  //       subItems: [
  //         {
  //           label: 'Monthly Summary Report',
  //           link: '/apps/mis/monthly-summary-report',
  //         },
  //       ]
  //     }
  //   ]
  // },
  {
    label: 'Employee Salary',
    icon: 'users',
    subItems: [
      {
        label: 'Salary List',
        link: '/employee-salary/salary-details',
      },
      {
        label: 'Salary Entry',
        link: '/employee-salary/salary-entry',
      },
      {
        label: 'Salary List',
        link: '/employee-salary/salary-list',
      }
    ]
  },
  {
    label: 'Customer management',
    icon: 'credit-card',
    subItems: [
      {
        label: 'Create Lead',
        link: '/crm/create-lead',
      },
      {
        label: 'Assign Lead',
        link: '/crm/assign-lead',
      },
      {
        label: 'Lead Follow Up',
        link: '/crm/lead-follow-up',
      },
      {
        label: 'Follow Up History',
        link: '/crm/follow-up-history',
      },
      {
        label: 'Due Flow Up',
        link: '/crm/due-flow-up',
      },
      {
        label: 'SMS',
        link: '/crm/sms',
      }
    ]
  },
  // {
  //   label: 'Loans',
  //   icon: 'credit-card',
  //   subItems: [
  //     {
  //       label: 'LoanPayments',
  //       link: '/loans/loanpayments',
  //     },
  //     {
  //       label: 'LoanList',
  //       link: '/loans/loanlist',
  //     },
  //   ]
  // },
  {
    label: 'RdFd',
    icon: 'database',
    subItems: [
      {
        label: 'OpenAccount',
        link: '/rdfd/openaccount',
      },
      {
        label: 'Receipt-entry',
        subItems: [
          {
            label: 'Receipt-entry',
            link: '/rdfd/Receipt-entry/recepitentry',
          },
          {
            label: 'Receipt-List',
            link: '/rdfd/Receipt-entry/receiptlist',
          },

        ]

      },
      {
        label: 'WithDrawal',
        subItems: [
          {
            label: 'Rd&Fd-Withdrawal',
            link: '/rdfd/withdrawal/rdfdwithdrawal',
          },
          {
            label: 'WithDrawal-List',
            link: '/rdfd/withdrawal/withdrawalist'
          },
        ]
      },
      {
        label: 'Report',
        subItems: [
          {
            label: 'InterestPayout',
            link: '/rdfd/Report/interestpayout',
          },
          {
            label: 'PrematureReport',
            link: '/rdfd/Report/prematurereport',
          },
          {
            label: 'Renewalreport',
            link: '/rdfd/Report/renewalreport',
          }
        ]
      }
    ]
  },

  {
    label: 'Loans',
    icon: 'users',
    subItems: [
      {
        label: 'Loan List',
        link: '/loans/loanlist',
      },
      {
        label: 'Loan-Receipt Entry',
        link: '/loans/loanreceipt',
      },
      {
        label: 'Loan-Due Report',
        link: '/loans/loandue',
      },
      {
        label: 'Loan Payments',
        link: '/loans/loanpayments'
      },
    ]
  },
  {
    label: 'Reports',
    icon: 'database',
    subItems: [
      {
        label: 'Collection',
        subItems: [
          {
            label: 'CollagentWise',
            link: '/report/Collection/collagentwise',
          },
          {
            label: 'Area-Wise',
            link: '/report/Collection/areawise',
          }
        ]
      },
      {
        label: 'Installation Dues',
        subItems: [
          {
            label: 'Advisorwise',
            link: '/report/Installation Dues/advisorwise',
          },
          {
            label: 'Memeberwise',
            link: '/report/Installation Dues/memberwise',
          },
        ]
      },
      {
        label: 'Payments',
        subItems: [
          {
            label: 'Advisor',
            link: '/report/Payments/advisor',
          },
          {
            label: 'Incentive Payments',
            link: '/report/Payments/incentive',
          },
          {
            label: 'Loan-Payments',
            link: '/report/Payments/loanpayments',
          },
          {
            label: 'Return-Payments',
            link: '/report/Payments/returnpayments',
          },
        ]
      },
      {
        label: 'MIS',
        subItems: [
          {
            label: 'Money Summary',
            link: '/report/MIS/moneysummary',
          },
          {
            label: 'Closing',
            link: '/report/MIS/closing',
          },
        ]
      },
      {
        label: 'Auction Report',
        subItems: [
          {
            label: 'Agreement Of Guarantee',
            link: '/report/Auction Report/agreementofguarantee'
          },
          {
            label: 'Auction Receipt',
            link: '/report/Auction Report/auctionreceipt',
          },
          {
            label: 'Bidauthorization',
            link: '/report/Auction Report/bidauthorization',
          },
          {
            label: 'Bidpayablememo',
            link: '/report/Auction Report/bidpayablememo',
          },
          {
            label: 'Demandnote',
            link: '/report/Auction Report/demandnote',
          },
          {
            label: 'Guarantor Report',
            link: '/report/Auction Report/guarantorreport',
          },
          {
            label: 'Lien confirmation',
            link: '/report/Auction Report/lienconfirmation',
          },
          {
            label: 'NOC Letter',
            link: '/report/Auction Report/nocletter',
          },
          {
            label: 'PrizeCommitment',
            link: '/report/Auction Report/prizecommitment',
          },
          {
            label: 'Prize Payment Slip',
            link: '/report/Auction Report/prizepaymentslip',
          },
          {
            label: 'UPD Report',
            link: '/report/Auction Report/udpconfirmation',
          },
        ]
      },
      {
        label: 'Group Report',
        subItems: [
          {
            label: 'Auction Prize Sign',
            link: '/report/Group Report/auctionprizesign',
          },
          {
            label: 'Chit Schedule',
            link: '/report/Group Report/chitschedule',
          },
          {
            label: 'GroupAuctionList',
            link: '/report/Group Report/groupauction',
          },
          {
            label: 'GroupBalanceSheet',
            link: '/report/Group Report/groupbalancesheet',
          },
          {
            label: 'GroupLedger',
            link: '/report/Group Report/groupledger',
          },
          {
            label: 'Group Member Print',
            link: '/report/Group Report/groupmemberprint',
          },
          {
            label: 'Receipt Payments',
            link: '/report/Group Report/receiptpayments',
          },
          {
            label: 'Remove List',
            link: '/report/Group Report/removelist',
          },
          {
            label: 'Vacancy Report',
            link: '/report/Group Report/vacanyreport',
          },
          {
            label: 'Welcome Letter',
            link: '/report/Group Report/welcomeletter',
          },
        ]
      },
      {
        label: 'Receipt Report',
        subItems: [
          {
            label: 'Member Group Ledger',
            link: '/report/Receipt Report/membergroupledger',
          },
          {
            label: 'Member Ledger',
            link: '/report/Receipt Report/memberledger',
          },
          {
            label: 'Adjustment Report',
            link: '/report/Receipt Report/adjustmentreport',
          },
          {
            label: 'Daily Collection Summary',
            link: '/report/Receipt Report/dailycollectionsummary',
          },
          {
            label: 'Daily Transaction',
            link: '/report/Receipt Report/dailytransation',
          },
        ]
      },
    ]
  }
];