import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../../views/pages/auth/UserDetails/user.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private user: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.isAuthorized()) {
      let roles = route.data["roles"] as Array<string>;
      if (roles) {
        var match = this.user.roleMatch(roles);
        if (match) return true;
        else {
          this.router.navigate(['/forbidden']);
          return false;
        }
      }
      else
        return true;
    }
    // // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']);
    return true;
  }
}