export const environment = {
  production: false,
  //ApiSwagger: 'http://52.140.40.53:8086/api/',
  ApiSwagger: 'http://localhost:64832/api/',
  /* API_Base_URL: 'http://148.66.130.255/chitfund/api/',
  API_Download_URL: 'http://148.66.130.255/chitfund/', */
  
  //API_Base_URL: 'http://localhost:64832/api/',
  //API_Base_URL: 'http://cfmsapi.esdinfra.com/api/',
  //API_Login_URL: 'http://localhost:64832/',
  //API_Login_URL: 'http://cfmsapi.esdinfra.com/',
  //API_Download_URL: 'http://localhost:64832/',
  //API_Download_URL: 'http://cfmsapi.esdinfra.com/',

//dell server
  API_Base_URL: 'http://106.51.65.164:8087/api/',
  API_Login_URL: 'http://106.51.65.164:8087/',
  API_Download_URL: 'http://106.51.65.164:8087/'

  
//dilip check//123

};
